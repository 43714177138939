<template>
  <div class="settings">
    <van-nav-bar
      title="Einstellungen"
      fixed
      placeholder
    >
      <template #left>
        <van-icon
          :name="$icon('back')"
          size="16"
          color="#000"
          is-link
          @click="$router.back()"
        />
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-cell
        :title="$t('password')"
        size="large"
        is-link
        :to="{ name: 'account' }"
      />
      <van-cell
        :title="$t('imprint.title')"
        size="large"
        is-link
        :to="{ name: 'imprint' }"
      />
      <van-cell
        :title="$t('terms.title')"
        size="large"
        is-link
        :to="{ name: 'terms' }"
      />
      <van-cell
        :title="$t('privacy.title')"
        size="large"
        is-link
        :to="{ name: 'privacy' }"
      />
      <van-cell
        :title="$t('privacy.cookieSettings')"
        size="large"
        is-link
        @click="handleCookieSettings"
      />
      <van-cell
        :title="$t('cancelAccount.title')"
        size="large"
        is-link
        :to="{ name: 'cancelAccount' }"
      />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  methods: {
    handleCookieSettings() {
      // Invoke Consentmanager screen.
      // eslint-disable-next-line no-underscore-dangle
      window.__cmp('showScreen');
    },
  },
};
</script>

<style lang="scss">
/* .settings {
} */
</style>
